<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">motion tracker</div>
      </v-row>
      <v-row class="">
        <div class="">
          Create and edit motions. Track motions and bills and mark as a bill or
          act when it passes each stage.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-dialog width="auto" v-model="addDialog" :persistent="addLoader">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs"
              ><v-icon left>mdi-plus</v-icon> new motion</v-btn
            >
          </template>

          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">create new motion</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-form v-model="addForm" ref="addForm">
                <v-autocomplete
                  label="Author"
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="author"
                ></v-autocomplete>

                <v-text-field
                  label="Title/Chapter"
                  :rules="[rules.required]"
                  outlined
                  dense
                  v-model="title"
                ></v-text-field>

                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :rules="[rules.required]"
                      label="Notice Date"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>

                <v-textarea
                  label="Description"
                  :rules="[rules.required]"
                  outlined
                  dense
                  v-model="description"
                ></v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="addDialog = false">cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!addForm"
                :loading="addLoader"
                @click="motionCreate"
              >
                create motion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="motions"
        class="text-capitalize"
      >
        <template v-slot:[`item.intro`]="{ item }">
          {{ item.intro | moment("Do MMMM YYYY") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              small
              rounded
              :color="item.passed ? 'success' : 'primary'"
              text
              outlined
              @click="billCreate(item)"
              >{{ item.passed ? "passed" : "bill" }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              rounded
              color="primary"
              text
              outlined
              @click="makeAct(item)"
              >act</v-btn
            >
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="makeBillDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark motion as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="makeBill" ref="makeBill">
              <v-text-field
                label="Ordinance No"
                v-model="ordinanceNumber"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
              <v-file-input
                label="Attach Document"
                :rules="[rules.required]"
                outlined
                dense
                v-model="billDocument"
              ></v-file-input>
            </v-form>

            <v-btn
              color="primary"
              :disabled="!makeBill"
              :loading="billLoader"
              block
              >pass motion</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog width="auto" v-model="makeActDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark bill as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="actForm" ref="actForm">
              <v-text-field
                label="Act Number"
                :rules="[rules.required]"
                outlined
                dense
                v-model="actNumber"
              ></v-text-field>
              <v-file-input
                label="Attach Document"
                :rules="[rules.required]"
                outlined
                dense
                v-model="actDocument"
              ></v-file-input>
            </v-form>

            <v-btn
              color="primary"
              :disabled="!actForm"
              :loading="actLoader"
              block
              >pass bill</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { motionStore } from "@/stores/motions";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {
      author: "",
      title: "",
      commitee: "",
      commiteeItems: [],
      description: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateMenu: false,
      search: "",
      headers: [
        {
          text: "title/chapter",
          value: "title_or_chapter",
          class: "text-uppercase black--text",
        },
        {
          text: "author",
          value: "motion_id",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "committee_id",
          class: "text-uppercase black--text",
        },
        {
          text: "introduction date",
          value: "introduction_date",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "35%",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      billDocument: null,
      actDocument: null,
      makeBillDialog: false,
      makeActDialog: false,
      actNumber: "",
      ordinanceNumber: "",
      addLoader: false,
      addForm: false,
      addDialog: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      makeBill: false,
      actForm: false,
      billForm: false,
      actLoader: false,
      billLoader: false,
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      activeItemId: null,
    };
  },
  computed: {
    ...mapState(motionStore, ["motion", "motions"]),
  },
  methods: {
    ...mapActions(motionStore, [
      "getMotions",
      "createMotion",
      "createBill",
      "createAct",
      "editMotion",
      "editBill",
      "editAct",
      "deleteMotion",
    ]),
    motionCreate() {
      this.addLoader = true;
      this.createMotion({
        author: this.author,
        title: this.title,
        noticeDate: this.date,
        description: this.description,
      })
        .then(() => {
          this.$refs.addForm.reset();
          this.showSnackBar(true, "motion created succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.addLoader = false;
          this.addDialog = false;
        });
    },
    motionUpdate() {
      if (this.makeBillDialog) {
        this.billLoader = true;
        this.editMotion(this.activeItemId, {
          ordinance_no: this.ordinanceNumber,
          file: this.billDocument,
        })
          .then(() => {
            this.$refs.billForm.reset();
            this.showSnackBar(true, "motion updated succesfully");
          })
          .catch((err) => {
            this.showSnackBar(false, err.response.data.message);
          })
          .finally(() => {
            this.billLoader = false;
            this.makeBillDialog = false;
          });
      } else {
        this.actLoader = true;
        this.editMotion(this.activeItemId, {
          ordinance_no: this.actNumber,
          file: this.actDocument,
        })
          .then(() => {
            this.$refs.actForm.reset();
            this.showSnackBar(true, "bill updated succesfully");
          })
          .catch((err) => {
            this.showSnackBar(false, err.response.data.message);
          })
          .finally(() => {
            this.actLoader = false;
            this.makeActDialog = false;
          });
      }
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
    billCreate(item) {
      this.activeItemId = item.id;
      if (!item.passed) {
        this.makeBillDialog = true;
      }
    },
    makeAct(item) {
      this.activeItemId = item.id;
      if (!item.passed) {
        this.makeActDialog = true;
      }
    },
  },
  mounted() {
    this.getMotions();
  },
};
</script>

<style></style>
